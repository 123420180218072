/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useEffect, useState } from 'react';
import { Box, Button, Code, Flex, HStack, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import { type Protocol } from '../../interface/Protocol';
import { DetailsElement } from '../detailsDrawer/DetailsElement';
import { RoundedBox } from '../RoundedBox';
import { BKTraceCallstack } from './BKTraceCallstack';
import moment from 'moment';
import { type Log, type Request } from '../../interface/Crash';
import { convertCrashToHar } from '../../helper/HarConverter';
import CsvDownloadButton from 'react-json-to-csv';

interface Props {
    protocolId: string;
    details: Protocol;
}

export const BKTrace: FunctionComponent<Props> = (props: Props) => {
    const { protocolId, details } = props;
    const [collectionRequest, setCollection] = useState();
    const [collectionTransaction, setCollectionTransaction] = useState<Record<string, [object]>>();
    const [collectionShare, setCollectionShare] = useState<Record<string, [object]>>();

    useEffect(() => {
        if (!collectionRequest) setCollection(collectRequests(details));
        if (!collectionTransaction) setCollectionTransaction(collectMessageLoggings(details, 'Processing transaction'));
        if (!collectionShare) setCollectionShare(collectMessageLoggings(details, 'Processing shares'));
    }, [collectionRequest, collectionTransaction, collectionShare]);

    return (
        <>
            <Flex gap={6}>
                {collectionRequest && (
                    <Menu>
                        <MenuButton as={Button} colorScheme="blue">
                            HAR download
                        </MenuButton>
                        <MenuList minWidth="240px">
                            {Object.entries(collectionRequest).map(([file, entries]) => {
                                return (
                                    <MenuGroup key={file} title={file}>
                                        {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-expect-error
                                            Object.entries(entries).map(([sessionId, requests]) => {
                                                return (
                                                    <MenuItem
                                                        key={sessionId}
                                                        value={sessionId}
                                                        onClick={() => {
                                                            protocolToHarConverter(collectionRequest, file, sessionId);
                                                        }}
                                                    >
                                                        {
                                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                            // @ts-expect-error
                                                            `${sessionId} (request count: ${requests.length})`
                                                        }
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </MenuGroup>
                                );
                            })}
                        </MenuList>
                    </Menu>
                )}
                {collectionTransaction && Object.keys(collectionTransaction).length > 0 && (
                    <>
                        <Menu>
                            <MenuButton as={Button} colorScheme="blue">
                                Transactions to CSV
                            </MenuButton>
                            <MenuList minWidth="240px">
                                {Object.entries(collectionTransaction).map(([sessionId, entries]) => {
                                    return (
                                        <MenuItem key={sessionId} value={sessionId}>
                                            <CsvDownloadButton
                                                key={sessionId}
                                                data={entries.map((e) => splitAmountCurrency(e)) || []}
                                            >
                                                {`${sessionId} (Entries count: ${entries.length})`}
                                            </CsvDownloadButton>
                                        </MenuItem>
                                    );
                                })}
                            </MenuList>
                        </Menu>
                        <Menu>
                            <MenuButton as={Button} colorScheme="blue">
                                Transactions JSON
                            </MenuButton>
                            <MenuList minWidth="240px">
                                {Object.entries(collectionTransaction).map(([sessionId, entries]) => {
                                    return (
                                        <MenuItem
                                            key={sessionId}
                                            value={sessionId}
                                            onClick={() => {
                                                protocolTransactionsJsonDownload(entries);
                                            }}
                                        >
                                            {`${sessionId} (Entries count: ${entries.length})`}
                                        </MenuItem>
                                    );
                                })}
                            </MenuList>
                        </Menu>
                    </>
                )}
                {collectionShare && Object.keys(collectionShare).length > 0 && (
                    <Menu>
                        <MenuButton as={Button} colorScheme="blue">
                            Shares to CSV
                        </MenuButton>
                        <MenuList minWidth="240px">
                            {Object.entries(collectionShare).map(([sessionId, entries]) => {
                                return (
                                    <MenuItem key={sessionId} value={sessionId}>
                                        <CsvDownloadButton
                                            key={sessionId}
                                            data={entries.map((e) => splitAmountCurrency(e)) || []}
                                        >
                                            {`${sessionId} (Entries count: ${entries.length})`}
                                        </CsvDownloadButton>
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </Menu>
                )}
            </Flex>
            {details.trace.map((item, index) => {
                return (
                    <RoundedBox key={index}>
                        <HStack>
                            <DetailsElement
                                pb={0}
                                boxProps={{ flex: '1' }}
                                label="Date"
                                value={moment(item.created.split('.')[0]).format('YYYY-MM-DD HH:mm:ss')}
                            />
                            <DetailsElement
                                pb={0}
                                boxProps={{ flex: '1' }}
                                label="Session-Id"
                                value={item['session-id']}
                            />
                        </HStack>
                        {item.type === 'outbank.python.callstack' ? (
                            <BKTraceCallstack protocolId={protocolId} trace={item} traceIndex={index} />
                        ) : (
                            <Code mt={5} p={3}>
                                {item.message}
                            </Code>
                        )}
                    </RoundedBox>
                );
            })}
        </>
    );
};

const collectRequests = (details: Protocol): any => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requestCollection: any = {};
    details.trace.forEach((item) => {
        const file = item?.body?.file ?? '';
        const sessionId = item['session-id'];
        if (file.trim()) {
            if (file in requestCollection && sessionId in requestCollection[`${file}`]) {
                requestCollection[`${file}`][`${sessionId}`].push(handleBodyObject(item.body!));
            } else if (file in requestCollection && !(sessionId in requestCollection[`${file}`])) {
                requestCollection[`${file}`][`${sessionId}`] = [handleBodyObject(item.body!)];
            } else {
                requestCollection[`${file}`] = {};
                requestCollection[`${file}`][`${sessionId}`] = [handleBodyObject(item.body!)];
            }
        } else {
            // These are logger.infos without any request oder response object
        }
    });
    return requestCollection;
};

const collectMessageLoggings = (details: Protocol, identifier: string): any => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requestCollection: any = {};
    details.trace.forEach((item) => {
        const sessionId = item['session-id'];

        if (item.message && item.message.includes(identifier)) {
            if (sessionId in requestCollection) {
                requestCollection[`${sessionId}`].push(handleTransactionMessage(item.message));
            } else {
                requestCollection[`${sessionId}`] = [handleTransactionMessage(item.message)];
            }
        }
    });
    return requestCollection;
};

const handleBodyObject = (body: Log): any => {
    const bindings = body.frames[0].bindings;
    const requests = bindings.requests;
    if (requests) {
        return requests[1];
    }
};

const handleTransactionMessage = (message: string): any => {
    try {
        // regex uses look-forwards and look-behinds to select only single-quotes that should be selected
        const regex = /('(?=(,\s*')))|('(?=:))|((?<=([{:,]\s*))')|((?<={)')|('(?=}))/g;
        const _message = message.replace(regex, '"');
        // eslint-disable-next-line @typescript-eslint/quotes, quotes
        const _string = `{${_message.split('{')[1]}`;
        const data = JSON.parse(_string.trim());

        return data;
    } catch (error) {
        return { error: error };
    }
};

const splitAmountCurrency = (entry: any): any => {
    const copyEntry = structuredClone(entry);
    const keys = ['amount', 'OriginalAmount', 'price', 'originalValue', 'value', 'percentProfit'];
    for (const key of keys) {
        if (key in copyEntry) {
            const splitValue = copyEntry[key].split(' ');
            copyEntry[key] = parseFloat(splitValue[0]).toLocaleString('de-DE');
            copyEntry[`${key}Currency`] = splitValue[1];
        }
    }
    return copyEntry;
};

const protocolToHarConverter = (collection: Collection, fileName: string, sessionId: string): void => {
    const selectedCollection = collection[`${fileName}`][`${sessionId}`];
    if (selectedCollection) {
        const file = new Blob([JSON.stringify(convertCrashToHar(selectedCollection), null, 4)], { type: 'text/plain' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = `${fileName}@${sessionId}_` + moment().format('YY-MM-DD_HH-mm-ss') + '.har';

        // simulate link click
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        // remove link element
        element?.parentNode?.removeChild(element);
    }
};

const protocolTransactionsJsonDownload = (entries: any): void => {
    const file = new Blob([JSON.stringify(entries, null, 4)], { type: 'text/plain' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = 'export.json';

    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    // remove link element
    element?.parentNode?.removeChild(element);
};

type Collection = Record<string, Record<string, Request[]>>;
