import React, { type FunctionComponent } from 'react';
import { type Mandant } from '../../interface/AppData';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { ReactComponent as OutbankAppIcon } from '../../images/Outbank-App-Icon.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { ReactComponent as FPAppIcon } from '../../images/FP-App-Icon.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { ReactComponent as WaltAppIcon } from '../../images/Walt-App-Icon.svg';

interface Props {
    mandant: Mandant;
}

export const AppIcon: FunctionComponent<Props> = (props) => {
    switch (props.mandant) {
        case 'Outbank':
            return <OutbankAppIcon />;
        case 'Finanzpartner':
            return <FPAppIcon />;
        case 'Walt':
            return <WaltAppIcon />;
    }
};
