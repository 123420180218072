import React, { type FunctionComponent } from 'react';
import { Request } from '../helper';
import { Button, type ButtonProps, useToast } from '@chakra-ui/react';

type Props = ButtonProps & {
    buttonText: string;
    path: string;
    fileName?: string;
};

const fileDownload = async (path: string, name: string, toast?: any): Promise<void> => {
    await Request.download({ path }, name, toast);
};

export const DownloadButton: FunctionComponent<Props> = (props: Props) => {
    const toast = useToast({ position: 'top' });
    const { path, fileName, buttonText, ...rest } = props;
    return (
        <Button
            onClick={async (): Promise<void> => {
                await fileDownload(path, fileName ?? buttonText, toast);
            }}
            mt="2"
            {...rest}
        >
            {props.buttonText}
        </Button>
    );
};
