import {
    IoHome,
    IoMail,
    IoPaperPlane,
    IoAlbums,
    IoPeople,
    IoDocumentText,
    IoBarChart,
    IoCloudyNight,
    IoPartlySunny,
    IoImages,
    IoLayersSharp,
    IoNewspaper,
    IoFlash,
    IoLogoAppleAppstore,
    IoMailOpen,
    IoTelescope,
    IoBagHandleSharp,
    IoBarChartOutline,
    IoBarChartSharp,
    IoFlag,
} from 'react-icons/io5';

import { type IconType } from 'react-icons';
export interface NavigationElement {
    label: string;
    icon: IconType;
    active: boolean;
    link: string;
    scopes?: string[];
    external?: boolean;
}
type NavigationObjects = Record<string, NavigationElement[]>;

export const baseElements: NavigationElement[] = [
    {
        label: 'Home',
        icon: IoHome,
        active: false,
        link: '/',
    },
];

export const groupedNavElements: NavigationObjects = {
    WebConnect: [
        {
            label: 'Dashboard',
            icon: IoHome,
            active: false,
            link: '/webconnect',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Crashgroups',
            icon: IoAlbums,
            active: false,
            link: '/crashgroups',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Last Crashes',
            icon: IoLayersSharp,
            active: false,
            link: '/lastcrashes/200',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Feature Request',
            icon: IoNewspaper,
            active: false,
            link: '/featurerequests',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Importer Errors',
            icon: IoNewspaper,
            active: false,
            link: '/importererror',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Protocols',
            icon: IoDocumentText,
            active: false,
            link: '/protocols',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Users',
            icon: IoPeople,
            active: false,
            link: '/users',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'HAR Files',
            icon: IoMail,
            active: false,
            link: '/har',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Ground-Control',
            icon: IoFlash,
            active: false,
            link: '/groundcontrol',
            external: false,
            scopes: ['serviceportal_admin', 'serviceportal_groundcontrol'],
        },
        {
            label: 'Events',
            icon: IoBarChart,
            active: false,
            link: 'https://c.outbankapp.com/dashboard#/57679def682879d8db4eff61/analytics/events/key/WebConnect',
            external: true,
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_marketing'],
        },
    ],
    Support: [
        {
            label: 'License Manager',
            icon: IoPeople,
            active: false,
            link: 'https://lic.outbankapp.com/manager/',
            external: true,
            scopes: ['serviceportal_admin', 'serviceportal_license'],
        },
        {
            label: 'App Releases',
            icon: IoLogoAppleAppstore,
            active: false,
            link: '/apps',
            scopes: ['serviceportal_admin', 'serviceportal_app'],
        },
        {
            label: 'Milestones',
            icon: IoFlag,
            active: false,
            link: '/milestones',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Integrations',
            icon: IoPartlySunny,
            active: false,
            link: '/integrations',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Integrations (Extended)',
            icon: IoPartlySunny,
            active: false,
            link: '/integrationsExtended',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'New Integrations',
            icon: IoCloudyNight,
            active: false,
            link: '/newintegrations',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'User Requests',
            icon: IoTelescope,
            active: false,
            link: '/outbankfeature',
            scopes: ['serviceportal_default'],
        },
        {
            label: 'HAR Uploader',
            icon: IoPaperPlane,
            active: false,
            link: 'https://upload.outbankapp.com/',
            external: true,
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
    ],
    Localizations: [
        {
            label: 'Localizations',
            icon: IoDocumentText,
            active: false,
            link: '/localizations',
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk', 'serviceportal_app'],
        },
    ],
    Nightly: [
        {
            label: 'Finanzpartner',
            icon: IoImages,
            active: false,
            link: '/nightly/finanzpartner',
            external: false,
            scopes: ['serviceportal_admin', 'serviceportal_helpdesk'],
        },
        {
            label: 'Outbank',
            icon: IoImages,
            active: false,
            link: '/nightly/outbank',
            external: false,
            scopes: ['serviceportal_admin', 'serviceportal_helpdesk'],
        },
    ],
    Marketing: [
        {
            label: 'Apple Ads',
            icon: IoBagHandleSharp,
            active: false,
            link: '/marketing/apple-ads',
            external: false,
            scopes: ['serviceportal_admin', 'serviceportal_marketing'],
        },
    ],
    Tools: [
        {
            label: 'Figma Preview',
            icon: IoImages,
            active: false,
            link: '/figmapreview',
            external: false,
            scopes: ['serviceportal_default'],
        },
        {
            label: 'Mail Converter',
            icon: IoMailOpen,
            active: false,
            link: '/mailconverter',
            external: false,
            scopes: ['serviceportal_default'],
        },
        {
            label: 'Sampledata',
            icon: IoDocumentText,
            active: false,
            link: '/sampledata',
            external: false,
            scopes: ['serviceportal_default'],
        },
    ],
    Evaluation: [
        {
            label: 'Crash Records Statistic',
            icon: IoBarChartSharp,
            active: false,
            link: '/evaluation/crash-records',
            external: false,
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'Crash User Statistic',
            icon: IoBarChartOutline,
            active: false,
            link: '/evaluation/crash-user',
            external: false,
            scopes: ['serviceportal_admin', 'serviceportal_webconnect', 'serviceportal_helpdesk'],
        },
        {
            label: 'User Integrations',
            icon: IoPeople,
            active: false,
            link: '/evaluation/user-integrations',
            external: false,
            scopes: ['serviceportal_admin'],
        },
    ],
};
